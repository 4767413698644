import React, { Fragment } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { compressImageBase64, resizeImageBase64, api, submitForm, host, pesan } from '../Modul';
import Pagination from '../component/Pagination';
import imgUpload from '../assets/img/img-upload.png';
import imgLoading from '../assets/img/loading-upload.gif';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [{ "Posisi": "loading" }, { "Posisi": "loading" }, { "Posisi": "loading" }],
            Detail: {},
            JmlData: 0,
            Judul: "",
            Konten: "",
            Status: 1,
            NoUrut: "1",
            ArrKonten: [],
            dataAboutTeam: {},
            KontenProduct: {},
            DataProduk: [],
            ArrTeam: [],
            KontenQuotes: { quotes: [] },
            ID: 0,
            Order: "NoUrut",
            By: "ASC",
            Page: 1,
            q: "",
            saveCls: "fa fa-save",
            DataTeam: [],
            Foto: imgUpload,
            ChangeImg: "tidak",
            ImageSrc: imgUpload,
            KontenJoin: {},
            KontenSejarah: { data: [] }
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("/konten", { act: "data konten", Menu: "Home", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            if (sql.j > 0) {
                this.setState({ Data: sql.data, JmlData: sql.j, Detail: {}, JmlData: 1, Judul: "", Konten: "", Status: 1, NoUrut: "1", ArrKonten: [], dataAboutTeam: { team: [] }, ArrTeam: [], KontenProduct: { produk: [] }, KontenQuotes: { quotes: [] }, KontenJoin: {}, ImageSrc: imgUpload, ID: 0, saveCls: "fas fa-save" });
            } else {
                this.setState({ Data: [{ data: "", }], JmlData: sql.j, Detail: {}, JmlData: 1, Judul: "", Konten: "", Status: 1, NoUrut: "1", ArrKonten: [], dataAboutTeam: { team: [] }, ArrTeam: [], KontenProduct: { produk: [] }, KontenQuotes: { quotes: [] }, KontenJoin: {}, ImageSrc: imgUpload, ID: 0, saveCls: "fas fa-save" });
            }
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    async handleDetail(i) {
        let Detail = this.state.Data[i];
        let sql = await api("/konten", { act: "detail konten", ID: Detail.ID });
        if (sql.status == "sukses") {
            Detail.Konten = sql.data.Konten;
            if (Detail.KontenType == "Text") {
                this.setState({
                    Judul: Detail.Judul,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    Detail: Detail
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenText'), {});
                modal.show("#modalKontenText");
            } else if (Detail.KontenType == "ArrayIcon") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: Detail.Konten,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    ArrKonten: data,
                    Detail: Detail
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayIcon'), {});
                modal.show("#modalKontenArrayIcon");
            } else if (Detail.KontenType == "ArrayTeam") {
                let sql = await api("master", { act: "data anggota", q: "", order: "NamaDepan", by: "ASC", page: 1 });
                this.setState({
                    Judul: Detail.Judul,
                    Konten: JSON.parse(Detail.Konten),
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    Detail: Detail,
                    DataTeam: sql.data
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayTeam'), {});
                modal.show("#modalKontenArrayTeam");
            } else if (Detail.KontenType == "ArrayImages") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: Detail.Konten,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    ArrKonten: data,
                    Detail: Detail
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayImages'), {});
                modal.show("#modalKontenArrayImages");
            } else if (Detail.KontenType == "ArrayProduk") {
                let Produk = await api("/master", { act: "data produk", order: "Nama", by: "ASC", page: 1, q: "" });
                let data = JSON.parse(Detail.Konten);
                console.log(data);
                this.setState({
                    Judul: Detail.Judul,
                    KontenProduct: data,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    DataProduk: Produk.data,
                    Detail: Detail
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayProduk'), {});
                modal.show("#modalKontenArrayProduk");
            } else if (Detail.KontenType == "ArrayCard") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: Detail.Konten,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    ArrKonten: data,
                    Detail: Detail
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayCard'), {});
                modal.show("#modalKontenArrayCard");
            } else if (Detail.KontenType == "AboutTeam") {
                let team = await api("master", { act: "data anggota", q: "", order: "NamaDepan", by: "ASC", page: 1 });
                console.log(JSON.parse(Detail.Konten));
                this.setState({
                    Judul: Detail.Judul,
                    dataAboutTeam: JSON.parse(Detail.Konten),
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    Detail: Detail,
                    DataTeam: team.data
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenAboutTeam'), {});
                modal.show("#modalKontenAboutTeam");
            } else if (Detail.KontenType == "ArraySejarah") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    KontenSejarah: data,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    ImageSrc: host.replace("/cp", "") + data.background
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArraySejarah'), {});
                modal.show("#modalKontenArraySejarah");
            } else if (Detail.KontenType == "ArrayQuotes") {
                let data = JSON.parse(Detail.Konten);
                console.log(data);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: Detail.Konten,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    KontenQuotes: data,
                    Detail: Detail,
                    ImageSrc: host.replace("/cp", "") + data.background
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayQuotes'), {});
                modal.show("#modalKontenArrayQuotes");
            } else if (Detail.KontenType == "CardJoin") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    KontenJoin: data,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    Detail: Detail,
                    ImageSrc: host.replace("/cp", "") + data.background
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenCardJoin'), {});
                modal.show("#modalKontenCardJoin");
            } else if (Detail.KontenType == "ArrayObject") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: data,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    Detail: Detail,
                    ImageSrc: host.replace("/cp", "") + data.background
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayObject'), {});
                modal.show("#modalKontenArrayObject");
            }
        } else {
            pesan("Info", sql.pesan, "danger");
        }
    }

    handleChangeArrayKonten(i, arr, val) {
        let data = this.state.ArrKonten;
        data[i][arr] = val;
        let Detail = this.state.Detail;
        Detail.Konten = JSON.stringify(data);
        this.setState({ Detail: Detail, Konten: JSON.stringify(data) });
    }

    handleAddArrayKonten(obj) {
        let data = this.state.ArrKonten;
        data.push(obj);
        this.setState({ ArrKonten: data, Konten: JSON.stringify(data) })
    }

    handleDeleteArrayKonten(i) {
        let data = this.state.ArrKonten;
        data.splice(i, 1);
        this.setState({ ArrKonten: data, Konten: JSON.stringify(data) })
    }

    openFile(e, ii, from = "konten") {
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = fileSizeKB > 600 ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
                        if (from == "konten") {
                            this.handleChangeArrayKonten(ii, "foto", newRes);
                        } else if (from == "quotes") {
                            let data = this.state.dataAboutTeam;
                            data.background = newRes;
                            this.setState({ Konten: data });
                        } else {
                            this.handleChangeArrAboutTeam(ii, "foto", newRes);
                        }
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };

            reader.readAsDataURL(file);
        }
    }

    handlePilihBG(e, compress = false) {
        this.setState({ ImageSrc: imgLoading });
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 && compress == true ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        let ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);
                        let pngData = canvas.toDataURL('image/png');
                        let newRes = fileSizeKB > 600 && compress == true ? await resizeImageBase64(pngData, height / 5, width / 5, fileExtension) : pngData;
                        this.setState({ ChangeImg: "ya", ImageSrc: newRes });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    handleChangeAboutTeam(obj, val) {
        let data = this.state.dataAboutTeam;
        data[obj] = val;
        this.setState({ dataAboutTeam: data, Konten: data });
    }

    handleChangeArrAboutTeam(i, obj, val) {
        let team = this.state.ArrTeam;
        team[i][obj] = val;
        let data = this.state.dataAboutTeam;
        data.team = team;
        this.setState({ dataAboutTeam: data, ArrTeam: team, Konten: data });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">{this.props.Title}</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" id="divTableAbsen">
                            <table className="table table-striped">
                                <thead className='bg-thead'>
                                    <tr>
                                        <th></th>
                                        <th onClick={() => this.handleSort("Posisi")}>Posisi</th>
                                        <th onClick={() => this.handleSort("Konten")}>Judul</th>
                                        <th onClick={() => this.handleSort("NoUrut")}>Nomer</th>
                                        <th onClick={() => this.handleSort("Status")}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Data.map((tr, i) => {
                                            let Status = tr.Status == 1 ? <span className="badge text-bg-primary">Aktif</span> : <span className="badge text-bg-danger">Tidak Aktif</span>;
                                            if (this.state.JmlData > 0) {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <button className="btn" type="button" tooltip="Edit" onClick={() => this.handleDetail(i)}>
                                                                <i className="fas fa-edit"></i>
                                                            </button>
                                                        </td>
                                                        <td>{tr.Posisi}</td>
                                                        <td>{tr.Judul}</td>
                                                        <td>{tr.NoUrut}</td>
                                                        <td>{Status}</td>
                                                    </tr>
                                                )
                                            } else {
                                                if (this.state.Data.length == 0) {
                                                    return (
                                                        <tr key={i}>
                                                            <td colSpan={6}>Tidak ada data</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (<tr>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                    </tr>)
                                                }
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Untuk text */}
                <div className="modal fade" id="modalKontenText" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Konten</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={this.state.Detail.Konten}
                                            onChange={(e) => this.setState({ Detail: { ...this.state.Detail, Konten: e } })}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['link', 'image'],
                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                                                    [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#cccccc', '#888888'] }],
                                                    ['clean'],
                                                ],
                                            }}
                                        />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type='hidden' name='Konten' value={this.state.Detail.Konten} />
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusText" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusText">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalText'>Batal</button>
                                    <button type="submit" className="btn btn-default"> <i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Icon */}
                <div className="modal fade" id="modalKontenArrayIcon" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={(e) => submitForm(e, { close: "btnTutupModalArrayIcon", crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={this.state.Konten} />
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddArrayKonten({ icon: "", text: "" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Ikon</th>
                                                    <th>Text</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <input type="text" value={tr.icon} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'icon', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.text} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'text', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayIcon'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Images */}
                <div className="modal fade" id="modalKontenArrayImages" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { close: "btnTutupModalArrayImages", crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={this.state.Konten} />
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddArrayKonten({ "foto": "", "nama": "", "keterangan": "" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        let images = tr.foto == "" ? imgUpload : tr.foto;
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <label htmlFor={`fileInput${ii}`} className="custom-file-upload">
                                                                    <input type="file" id={`fileInput${ii}`} accept='image/*' style={{ display: "none" }} onChange={(e) => this.openFile(e, ii)} />
                                                                    <img src={images} width="100px" id={`img-${ii}`} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.nama} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'nama', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.bagian} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'bagian', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayImages'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Card */}
                <div className="modal fade" id="modalKontenArrayCard" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>

                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={this.state.Konten} />
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th>Ikon</th>
                                                    <th>Judul</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td>
                                                                <input type="text" value={tr.icon} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'icon', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.judul} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'judul', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.keterangan} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'keterangan', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayCard'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk abour team */}
                <div className="modal fade" id="modalKontenAboutTeam" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.dataAboutTeam)} />
                                    <h4>Konten</h4>
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.dataAboutTeam.judul} onChange={(e) => this.handleChangeAboutTeam('judul', e.target.value)} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea type="number" className='form-control' value={this.state.dataAboutTeam.keterangan} onChange={(e) => this.handleChangeAboutTeam('keterangan', e.target.value)} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Email</th>
                                                    <th>Alamat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataTeam.map((tr, i) => {
                                                        let Status = false;
                                                        for (let dd of this.state.dataAboutTeam.team) if (dd == tr.ID) Status = true;
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" name='IDTeam[]' type="checkbox" role="switch" id={"IDTeam" + i} checked={Status} onChange={(e) => {
                                                                            let data = this.state.dataAboutTeam;
                                                                            let team = data.team;
                                                                            if (e.target.checked == true) {
                                                                                team.push(tr.ID);
                                                                                data.team = team;
                                                                                this.setState({ dataAboutTeam: data });
                                                                            } else {
                                                                                let temp = [];
                                                                                for (let dd of team) {
                                                                                    if (dd != tr.ID) temp.push(dd);
                                                                                }
                                                                                data.team = temp;
                                                                                this.setState({ dataAboutTeam: data });
                                                                            }
                                                                        }} />
                                                                        <label className="form-check-label" htmlFor={"IDTeam" + i}>
                                                                            <img src={host.replace("cp", "") + tr.Foto} width="100px" height="100px" />
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td>{tr.Nama}</td>
                                                                <td>{tr.Telp}</td>
                                                                <td>{tr.Email}</td>
                                                                <td>{tr.Alamat}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalAboutTeam'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Sejarah */}
                <div className="modal fade" id="modalKontenArraySejarah" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.KontenSejarah)} />
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Judul</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.KontenSejarah.data.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td>
                                                                <input type="text" value={tr.type} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.KontenSejarah;
                                                                    data.data[ii].type = e.target.value;
                                                                    this.setState({ KontenSejarah: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.judul} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.KontenSejarah;
                                                                    data.data[ii].judul = e.target.value;
                                                                    this.setState({ KontenSejarah: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.keterangan} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.KontenSejarah;
                                                                    data.data[ii].keterangan = e.target.value;
                                                                    this.setState({ KontenSejarah: data });
                                                                }} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='form-group'>
                                        <label>Background Images</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input name='Files' type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                            <img src={this.state.ImageSrc} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArraySejarah'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Produk */}
                <div className="modal fade" id="modalKontenArrayProduk" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' placeholder='Silahkan isi Konten' value={this.state.KontenProduct.keterangan} onChange={(e) => {
                                            let data = this.state.KontenProduct;
                                            data.keterangan = e.target.value;
                                            this.setState({ KontenProduct: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.KontenProduct)} />
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Rating</th>
                                                    <th>Harga</th>
                                                    <th>Faforit</th>
                                                    <th>Terjual</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataProduk.map((tr, ii) => {
                                                        let images = tr.Gambar == "" ? imgUpload : host.replace("cp/", "") + tr.Gambar;
                                                        let Status = false;
                                                        for (let dd of this.state.KontenProduct.produk) if (dd == tr.ID) Status = true;
                                                        return (<tr key={ii}>
                                                            <td>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" name='Produk[]' type="checkbox" role="switch" checked={Status} id={`chk${ii}`} onChange={(e) => {
                                                                        let data = this.state.KontenProduct;
                                                                        let produk = data.produk;
                                                                        if (e.target.checked == true) {
                                                                            produk.push(tr.ID);
                                                                            data.produk = produk;
                                                                            this.setState({ KontenProduct: data });
                                                                        } else {
                                                                            let temp = [];
                                                                            for (let dd of produk) {
                                                                                if (dd != tr.ID) temp.push(dd);
                                                                            }
                                                                            data.produk = temp;
                                                                            this.setState({ KontenProduct: data });
                                                                        }
                                                                    }} />
                                                                    <label className="form-check-label" htmlFor={`chk${ii}`}></label>
                                                                </div>
                                                            </td>
                                                            <td><img src={images} width="100px" id={`img-${ii}`} /></td>
                                                            <td>{tr.Nama}</td>
                                                            <td>{tr.Rating}</td>
                                                            <td>{tr.Harga}</td>
                                                            <td>{tr.IsFavorit == 1 ? "Ya" : "Tidak"}</td>
                                                            <td>{tr.Terjual}</td>
                                                            <td>{tr.keterangan}</td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayProduk'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Quotes */}
                <div className="modal fade" id="modalKontenArrayQuotes" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <input type='hidden' name="ChangeImg" value="tidak" />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan Testimoni</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.KontenQuotes.judul} onChange={(e) => {
                                            let data = this.state.KontenQuotes;
                                            data.judul = e.target.value;
                                            this.setState({ KontenQuotes: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.KontenQuotes)} />
                                    <p></p>
                                    <button type="button" className="btn btn-default" id="btnAddQuotes" onClick={() => {
                                        let data = this.state.KontenQuotes;
                                        let quotes = data.quotes;
                                        quotes.push({ oleh: "", title: "", quotes: "" });
                                        data.quotes = quotes;
                                        this.setState({ KontenQuotes: data });
                                    }}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th>Oleh</th>
                                                    <th>Siapa</th>
                                                    <th>Testimoni</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.KontenQuotes.quotes.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td>
                                                                <input type="text" value={tr.oleh} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.KontenQuotes;
                                                                    data.quotes[ii].oleh = e.target.value;
                                                                    this.setState({ KontenQuotes: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.title} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.KontenQuotes;
                                                                    data.quotes[ii].title = e.target.value;
                                                                    this.setState({ KontenQuotes: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.quotes} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.KontenQuotes;
                                                                    data.quotes[ii].quotes = e.target.value;
                                                                    this.setState({ KontenQuotes: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-danger" onClick={(e) => {
                                                                    let data = this.state.KontenQuotes;
                                                                    let temp = [];
                                                                    for (let i in data.quotes) if (i != ii) temp.push(data.quotes[i]);
                                                                    data.quotes = temp;
                                                                    this.setState({ KontenQuotes: data });
                                                                }} >
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <p></p>
                                        <div className='form-group'>
                                            <label>Background Images</label>
                                            <label className='d-flex justify-content-center align-items-center'>
                                                <input name='Files' type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                                <img src={this.state.ImageSrc} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayQuotes'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Card Join */}
                <div className="modal fade" id="modalKontenCardJoin" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <input type='hidden' name="ChangeImg" value="tidak" />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Judul Dua</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.KontenJoin.judul} onChange={(e) => {
                                            let data = this.state.KontenJoin;
                                            data.judul = e.target.value;
                                            this.setState({ KontenJoin: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.KontenJoin.keterangan} onChange={(e) => {
                                            let data = this.state.KontenJoin;
                                            let val = e.target.value;
                                            data.keterangan = val.replace(/\n/g, "<br />");
                                            data.keterangan = val.replace(/"/g, "");
                                            this.setState({ KontenJoin: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.KontenJoin)} />
                                    <div className='form-group'>
                                        <label>Background Images</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input name='Files' type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                            <img src={this.state.ImageSrc} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayQuotes'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Array Object */}
                <div className="modal fade" id="modalKontenArrayObject" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: 'konten', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <input type='hidden' name="ChangeImg" value="tidak" />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.Konten.keterangan} onChange={(e) => {
                                            let data = this.state.Konten;
                                            data.keterangan = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <h5>Form dalam banner</h5>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusForm" checked={this.state.Konten.isForm} onChange={(e) => {
                                            let data = this.state.Konten;
                                            data.isForm = e.target.checked == true ? 1 : 0;
                                            this.setState({ Konten: data });
                                        }} />
                                        <label className="form-check-label" htmlFor="chkStatusForm">Aktif</label>
                                    </div>
                                    <div className='form-group'>
                                        <label>Judul Form</label>
                                        <input type="text" className='form-control' value={this.state.Konten.judulForm} onChange={(e) => {
                                            let data = this.state.Konten;
                                            data.judulForm = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea type="text" className='form-control' placeholder='Silahkan isi Konten' value={this.state.Konten.keteranganForm} onChange={(e) => {
                                            let data = this.state.Konten;
                                            data.keteranganForm = e.target.value;
                                            this.setState({ Konten: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.Konten)} />
                                    <input type="hidden" name="Foto" value={this.state.ImageSrc} />
                                    <div className='form-group'>
                                        <label>Jenis Backgroud</label>
                                        <select className='form-select' name='jenis' value={this.state.Konten.jenis} onChange={(e) => {
                                            let data = this.state.Konten;
                                            data.jenis = e.target.value;
                                            this.setState({ Konten: data });
                                        }}>
                                            <option value="images">images</option>
                                            <option value="vidio">vidio</option>
                                        </select>
                                    </div>
                                    {
                                        this.state.Konten.jenis == "vidio" ?
                                            <div className='form-group'>
                                                <label>Background Vidio</label>
                                                <input name="Files" type='file' accept='video/mp4,video/x-m4v,video/*' className='form-control' />
                                            </div>
                                            :
                                            <div className='form-group'>
                                                <label>Background Images</label>
                                                <label className='d-flex justify-content-center align-items-center'>
                                                    <input name="Files" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e, false)} />
                                                    <img src={this.state.ImageSrc} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                                                </label>
                                            </div>

                                    }
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayObject'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Dashboard;
