import React, { Fragment } from 'react';
import { Token, saiki, compressImageBase64, resizeImageBase64, submitForm, host, api, pesan } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';

class MasterKelas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            JmlData: 1,
            Order: "Nama",
            By: "ASC",
            Page: 1,
            q: "",
            lblSubmit: "fa fa-save",
            btnSubmit: false,
            thead: [],
            tbody: [],
            ID: 0,
            Kode: "",
            Nama: "",
            Level: "",
            GuruID: "",
            Keterangan: "",
            Jurusan: "",
            Status: 1,
            dataGuru: [],
            dataJurusan: []
        };
    }

    componentDidMount() {
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("/master", { act: "data kelas", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "120px" },
                { text: "Kode", field: "Kode", sort: "Kode" },
                { text: "Nama", field: "Nama", sort: "Nama" },
                { text: "Level", field: "Level", sort: "Level" },
                { text: "Wali Kelas", field: "WaliKelas", sort: "WaliKelas" },
                { text: "Keterangan", field: "Keterangan", sort: "Keterangan" },
                { text: "Status", field: "Status", sort: "Status", type: "boolean" }
            ];

            this.setState({
                tbody: sql.data,
                thead: thead,
                JmlData: sql.j,
                Kode: "",
                Nama: "",
                Level: "",
                GuruID: "",
                Keterangan: "",
                Jurusan: "",
                Status: 1,
                lblSubmit: "fas fa-save",
                btnSubmit: false
            });
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        console.log(e.target.className);
        if (e.target.className == "page-link") {
            let Page = e.target.innerText == "" ? this.state.Page + 1 : e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    async handleDetail(data) {
        let sql = await api("master", { act: "detail kelas", ID: data.ID }, true);
        if (data != "baru") {
            this.setState({
                ID: data.ID,
                Kode: data.Kode,
                Nama: data.Nama,
                Level: data.Level,
                GuruID: data.GuruID,
                Keterangan: data.Keterangan,
                Jurusan: data.Jurusan,
                Status: data.Status,
                dataGuru: sql.guru,
                dataJurusan: sql.jurusan
            });
        } else {
            this.setState({
                ID: "",
                Kode: "",
                Nama: "",
                Level: "",
                GuruID: "",
                Keterangan: "",
                Jurusan: "",
                Status: 1,
                dataGuru: sql.guru,
                dataJurusan: sql.jurusan
            });
        }
        let modal = new window.bootstrap.Modal(document.getElementById('modalForm'), {});
        modal.show("#modalForm");
    }

    handleDelete(data) {
        this.setState({ ID: data.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDelete'), {});
        modal.show("#modalDelete");
    }

    openFile(e) {
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            reader.onload = async () => {
                try {
                    let baru = await compressImageBase64(reader.result, 'jpeg', 0.8);
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = await resizeImageBase64(baru, height / 10, width / 10);
                        this.setState({ Foto: newRes });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header">
                    <button className='btn btn-default' onClick={() => this.handleDetail("baru")}>Tambah Kelas</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">{this.props.Title}</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-edit", text: "Edit", opsi: (e) => this.handleDetail(e) },
                                { icon: "fas fa-trash", text: "Hapus", opsi: (e) => this.handleDelete(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Form Input edit */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { close: "btnTutupModalForm", crud: "master", fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            
                            <input type='hidden' name="act" value={this.state.ID == "" ? "tambah kelas" : "edit kelas"} />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah" : "Edit"} Kelas</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Kode</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Kode' name='Kode' value={this.state.Kode} onChange={(e) => this.setState({ Kode: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Nama</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Nama' name='Nama' value={this.state.Nama} onChange={(e) => this.setState({ Nama: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Level</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Level' name='Level' value={this.state.Level} onChange={(e) => this.setState({ Level: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Wali Kelas</label>
                                                <select className='form-select' name="GuruID" value={this.state.GuruID} onChange={(e) => this.setState({ GuruID: e.target.value })} required >
                                                    {
                                                        this.state.dataGuru.map((opt, i) => {
                                                            return (<option key={i} value={opt.ID}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Jurusan</label>
                                                <select className='form-select' name="Jurusan" value={this.state.Jurusan} onChange={(e) => this.setState({ Jurusan: e.target.value })} required >
                                                    {
                                                        this.state.dataJurusan.map((opt, i) => {
                                                            return (<option key={i} value={opt.ID}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' placeholder='Silahkan isi Status Keterangan' name='Keterangan' value={this.state.Keterangan} onChange={(e) => this.setState({ Keterangan: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <p></p>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusText" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusText">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalForm'>Batal</button>
                                    <button type="submit" className="btn btn-default" disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* modal Hapus */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form className='needs-validation' onSubmit={(e) => submitForm(e, { close: "btnTutupModalDelete", crud: "master", fn: (e) => this.handleMain(e) })} noValidate >
                            
                            <input type='hidden' name="act" value="hapus kelas" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Kelas</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghaus Kelas!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal" id='btnTutupModalDelete'>Batal</button>
                                    <button type="submit" className="btn btn-danger" > <i className={this.state.lblSubmit}></i> Hapus</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default MasterKelas;
