import React, { Fragment } from 'react';
import { Token, saiki, compressImageBase64, resizeImageBase64, submitForm, host, api, pesan } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';

class MasterWaliMurid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            JmlData: 1,
            Order: "NamaDepan",
            By: "ASC",
            Page: 1,
            q: "",
            lblSubmit: "fa fa-save",
            btnSubmit: false,
            thead: [],
            tbody: [],
            ID: 0,
            NIK: "",
            NamaDepan: "",
            NamaBelakang: "",
            TanggalLahir: saiki(),
            TempatLahir: "",
            Hubungan: "",
            JenisKelamin: "Laki-laki",
            Telp: "",
            Email: "",
            Alamat: "",
            Pendidikan: "",
            Pekerjaan: "",
            Status: 1
        };
    }

    componentDidMount() {
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("/master", { act: "data wali murid", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "120px" },
                { text: "NIK", field: "NIK", sort: "NIK" },
                { text: "Nama", field: "Nama", sort: "Nama" },
                { text: "Telp", field: "Telp", sort: "Telp" },
                { text: "Email", field: "Email", sort: "Email" },
                { text: "Status", field: "Status", sort: "Status", type: "boolean" }
            ];

            this.setState({
                tbody: sql.data,
                thead: thead,
                JmlData: sql.j,
                NIK: "",
                NamaDepan: "",
                NamaBelakang: "",
                TanggalLahir: saiki(),
                TempatLahir: "",
                Hubungan: "",
                JenisKelamin: "Laki-laki",
                Telp: "",
                Email: "",
                Alamat: "",
                Pendidikan: "",
                Pekerjaan: "",
                Status: 1,
                lblSubmit: "fas fa-save",
                btnSubmit: false
            });
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        console.log(e.target.className);
        if (e.target.className == "page-link") {
            let Page = e.target.innerText == "" ? this.state.Page + 1 : e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    async handleDetail(data) {
        if (data != "baru") {
            this.setState({
                ID: data.ID,
                NIK: data.NIK,
                NamaDepan: data.NamaDepan,
                NamaBelakang: data.NamaBelakang,
                TanggalLahir: data.TanggalLahir,
                TempatLahir: data.TempatLahir,
                Hubungan: data.Hubungan,
                JenisKelamin: data.JenisKelamin,
                Telp: data.Telp,
                Email: data.Email,
                Alamat: data.Alamat,
                Pendidikan: data.Pendidikan,
                Pekerjaan: data.Pekerjaan,
                Status: data.Status
            });
        } else {
            this.setState({
                ID: "",
                NIK: "",
                NamaDepan: "",
                NamaBelakang: "",
                TanggalLahir: saiki(),
                TempatLahir: "",
                Hubungan: "",
                JenisKelamin: "Laki-laki",
                Telp: "",
                Email: "",
                Alamat: "",
                Pendidikan: "",
                Pekerjaan: "",
                Status: 1
            });
        }
        let modal = new window.bootstrap.Modal(document.getElementById('modalForm'), {});
        modal.show("#modalForm");
    }

    handleDelete(data) {
        this.setState({ ID: data.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDelete'), {});
        modal.show("#modalDelete");
    }

    openFile(e) {
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            reader.onload = async () => {
                try {
                    let baru = await compressImageBase64(reader.result, 'jpeg', 0.8);
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = await resizeImageBase64(baru, height / 10, width / 10);
                        this.setState({ Foto: newRes });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header">
                    <button className='btn btn-default' onClick={() => this.handleDetail("baru")}>Tambah Wali Murid</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">{this.props.Title}</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-edit", text: "Edit", opsi: (e) => this.handleDetail(e) },
                                { icon: "fas fa-trash", text: "Hapus", opsi: (e) => this.handleDelete(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Form Input edit */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { close: "btnTutupModalForm", crud: "master", fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            
                            <input type='hidden' name="act" value={this.state.ID == "" ? "tambah wali murid" : "edit wali murid"} />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah" : "Edit"} Wali Murid</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>NIK</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi NIK' name='NIK' value={this.state.NIK} onChange={(e) => this.setState({ NIK: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Nama Depan</label>
                                                <input type="text" className='form-control' placeholder='Silahkan isi Nama Depan' name='NamaDepan' value={this.state.NamaDepan} onChange={(e) => this.setState({ NamaDepan: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Nama Belakang</label>
                                                <input type="text" className='form-control' placeholder='Silahkan isi Nama Belakang' name='NamaBelakang' value={this.state.NamaBelakang} onChange={(e) => this.setState({ NamaBelakang: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Tempat Lahir</label>
                                                <input type="text" className='form-control' placeholder='Silahkan isi Tempat Lahir' name='TempatLahir' value={this.state.TempatLahir} onChange={(e) => this.setState({ TempatLahir: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Tanggal Lahir</label>
                                                <input type="date" className='form-control' placeholder='Silahkan isi Tanggal Lahir' name='TanggalLahir' value={this.state.TanggalLahir} onChange={(e) => this.setState({ TanggalLahir: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Email</label>
                                                <input type="email" className='form-control' placeholder='Silahkan isi NIS' name='Email' value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Telp</label>
                                                <input type="number" maxLength={15} className='form-control' placeholder='Silahkan isi Telp' name='Telp' value={this.state.Telp} onChange={(e) => this.setState({ Telp: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Pendidikan</label>
                                                <select className='form-select' name="Pendidikan" value={this.state.Pendidikan} onChange={(e) => this.setState({ Pendidikan: e.target.value })} required>
                                                    <option value="SD">SD</option>
                                                    <option value="SMP">SMP</option>
                                                    <option value="SMA">SMA</option>
                                                    <option value="D1">D1</option>
                                                    <option value="D2">D2</option>
                                                    <option value="D3">D3</option>
                                                    <option value="S1">S1</option>
                                                    <option value="S2">S2</option>
                                                    <option value="S3">S3</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih data</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Pekerjaan</label>
                                                <input type="text" className='form-control' placeholder='Silahkan isi Pekerjaan' name='Pekerjaan' value={this.state.Pekerjaan} onChange={(e) => this.setState({ Pekerjaan: e.target.value })} required />
                                                <div className='invalid-feedback'>Silahkan Masukan Pekerjaan</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Hubungan Dengan Murid</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Hubungan dengan murid' name='Hubungan' value={this.state.Hubungan} onChange={(e) => this.setState({ Hubungan: e.target.value })} required />
                                        <div className='invalid-feedback'>Silahkan Masukan Hubungan</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Alamat</label>
                                        <textarea className='form-control' placeholder='Silahkan isi Status Alamat' name='Alamat' value={this.state.Alamat} onChange={(e) => this.setState({ Alamat: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Jenis Kelamin</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="JenisKelamin" value="Laki-laki" id="radioLaki" onChange={(e) => this.setState({ JenisKelamin: e.target.checked == true ? "Laki-laki" : "Perempuan" })} checked={this.state.JenisKelamin == "Laki-laki" ? true : false} />
                                            <label className="form-check-label" htmlFor="radioLaki">
                                                Laki-laki
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="JenisKelamin" value="Perempuan" id="radioPerempuan" onChange={(e) => this.setState({ JenisKelamin: e.target.checked == true ? "Perempuan" : "Laki-laki" })} checked={this.state.JenisKelamin == "Perempuan" ? true : false} />
                                            <label className="form-check-label" htmlFor="radioPerempuan">
                                                Perempuan
                                            </label>
                                        </div>
                                    </div>
                                    <p></p>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusText" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusText">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalForm'>Batal</button>
                                    <button type="submit" className="btn btn-default" disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* modal Hapus */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form className='needs-validation' onSubmit={(e) => submitForm(e, { close: "btnTutupModalDelete", crud: "master", fn: (e) => this.handleMain(e) })} noValidate >
                            
                            <input type='hidden' name="act" value="hapus wali murid" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Wali Murid</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghaus Wali Murid!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal" id='btnTutupModalDelete'>Batal</button>
                                    <button type="submit" className="btn btn-danger" > <i className={this.state.lblSubmit}></i> Hapus</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default MasterWaliMurid;
