import React, { Fragment } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { compressImageBase64, resizeImageBase64, extensionBase64, sizeBase64, api, submitForm, host } from '../Modul';
import Pagination from '../component/Pagination';
import imgLoading from '../assets/img/loading-upload.gif';
import imgUpload from '../assets/img/img-upload.png';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [{ "Posisi": "loading" }, { "Posisi": "loading" }, { "Posisi": "loading" }],
            Detail: {},
            JmlData: 0,
            Judul: "",
            Konten: "",
            Status: 1,
            NoUrut: "1",
            ArrKonten: [],
            ObjKonten: {},
            ID: 0,
            Order: "NoUrut",
            By: "ASC",
            Page: 1,
            q: "",
            saveCls: "fa fa-save",
            ImagesSrc: imgUpload,
            background: "",
            resolusi: 1,
            gambar: {
                size: "",
                width: "",
                height: ""
            },
            DataTeam: [],
            KontenTeam: [],
            ChangeImg: "tidak"
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("/konten", { act: "data konten", Menu: "Profile", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            if (sql.j > 0) {
                this.setState({
                    Data: sql.data,
                    JmlData: sql.j,
                    Detail: {},
                    JmlData: 1,
                    Judul: "",
                    Konten: "",
                    Status: 1,
                    NoUrut: "1",
                    ArrKonten: [],
                    ID: 0,
                    saveCls: "fas fa-save"
                });
            } else {
                this.setState({
                    Data: [{ data: "", }],
                    JmlData: 0,
                    Detail: {},
                    JmlData: 1,
                    Judul: "",
                    Konten: "",
                    Status: 1,
                    NoUrut: "1",
                    ArrKonten: [],
                    ID: 0,
                    saveCls: "fas fa-save"
                });
            }
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    async handleDetail(i) {
        let Detail = this.state.Data[i];
        let sql = await api("/konten", { act: "detail konten", ID: Detail.ID });
        if (sql.status == "sukses") {
            Detail.Konten = sql.data.Konten;
            console.log(Detail);
            if (Detail.KontenType == "Text") {
                this.setState({
                    Judul: Detail.Judul,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    Detail: Detail
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenText'), {});
                modal.show("#modalKontenText");
            } else if (Detail.KontenType == "ArrayIcon") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: Detail.Konten,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    ArrKonten: data,
                    Detail: Detail
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayIcon'), {});
                modal.show("#modalKontenArrayIcon");
            } else if (Detail.KontenType == "ArrayTeam") {
                let sql = await api("master", { act: "data anggota", q: "", order: "NamaDepan", by: "ASC", page: 1 });
                if (sql.status == "sukses") {
                    let data = JSON.parse(Detail.Konten);
                    this.setState({
                        Judul: Detail.Judul,
                        KontenTeam: JSON.parse(Detail.Konten),
                        Status: Detail.Status,
                        NoUrut: Detail.NoUrut,
                        ID: Detail.ID,
                        ArrKonten: data,
                        Detail: Detail,
                        DataTeam: sql.data
                    });

                    let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayTeam'), {});
                    modal.show("#modalKontenArrayTeam");
                }
            } else if (Detail.KontenType == "ArrayImages") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: Detail.Konten,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    ArrKonten: data,
                    Detail: Detail
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayImages'), {});
                modal.show("#modalKontenArrayImages");
            } else if (Detail.KontenType == "ArrayProduk") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: Detail.Konten,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    ArrKonten: data,
                    Detail: Detail
                });

                let modal = new window.bootstrap.Modal(document.getElementById('modalKontenArrayProduk'), {});
                modal.show("#modalKontenArrayProduk");
            } else if (Detail.KontenType == "ArrayObject") {
                let data = JSON.parse(Detail.Konten);
                this.setState({
                    Judul: Detail.Judul,
                    Konten: data,
                    Status: Detail.Status,
                    NoUrut: Detail.NoUrut,
                    ID: Detail.ID,
                    ObjKonten: data,
                    Detail: Detail
                });
                if (Detail.Posisi == "Banner Atas") {
                    if (data.background != "") this.setState({ ImagesSrc: host.replace("cp/", "") + data.background });
                    let modal = new window.bootstrap.Modal(document.getElementById('modalKontenBanerAtas'), {});
                    modal.show("#modalKontenBanerAtas");
                } else if (Detail.Posisi == "Body Kenapa") {
                    this.setState({ ArrKonten: data.card });
                    let modal = new window.bootstrap.Modal(document.getElementById('modalKontenBodyKenapa'), {});
                    modal.show("#modalKontenBodyKenapa");
                }
            }
        }
    }

    handleChangeArrayKonten(i, arr, val) {
        let data = this.state.ArrKonten;
        data[i][arr] = val;
        let Detail = this.state.Detail;
        Detail.Konten = JSON.stringify(data);
        this.setState({ Detail: Detail, Konten: JSON.stringify(data) });
    }

    handleAddArrayKonten(obj) {
        let data = this.state.ArrKonten;
        data.push(obj);
        this.setState({ ArrKonten: data, Konten: JSON.stringify(data) })
    }

    handleDeleteArrayKonten(i) {
        let data = this.state.ArrKonten;
        data.splice(i, 1);
        this.setState({ ArrKonten: data, Konten: JSON.stringify(data) })
    }

    openFile(e, ii, compress = false) {
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 && compress == true ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = fileSizeKB > 600 && compress == true ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
                        this.handleChangeArrayKonten(ii, "foto", newRes);
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };

            reader.readAsDataURL(file);
        }
    }

    handlePilihBG(e, compress = true) {
        let data = this.state.ObjKonten;
        this.setState({ ImagesSrc: imgLoading, ChangeImg: "ya" });
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 && compress == true ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = fileSizeKB > 600 && compress == true ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
                        data.background = newRes;
                        this.setState({ Konten: data, ImagesSrc: newRes, ChangeImg: "ya" });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">{this.props.Title}</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" id="divTableAbsen">
                            <table className="table table-striped">
                                <thead className='bg-thead'>
                                    <tr>
                                        <th></th>
                                        <th onClick={() => this.handleSort("Posisi")}>Posisi</th>
                                        <th onClick={() => this.handleSort("Konten")}>Judul</th>
                                        <th onClick={() => this.handleSort("NoUrut")}>Nomer</th>
                                        <th onClick={() => this.handleSort("Status")}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Data.map((tr, i) => {
                                            let Status = tr.Status == 1 ? <span className="badge text-bg-primary">Aktif</span> : <span className="badge text-bg-danger">Tidak Aktif</span>;
                                            if (this.state.JmlData > 0) {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <button className="btn" type="button" tooltip="Edit" onClick={() => this.handleDetail(i)}>
                                                                <i className="fas fa-pen-square"></i>
                                                            </button>
                                                        </td>
                                                        <td>{tr.Posisi}</td>
                                                        <td>{tr.Judul}</td>
                                                        <td>{tr.NoUrut}</td>
                                                        <td>{Status}</td>
                                                    </tr>
                                                )
                                            } else {
                                                if (this.state.Data.length == 0) {
                                                    return (
                                                        <tr key={i}>
                                                            <td colSpan={6}>Tidak ada data</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (<tr>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                        <td className="placeholder-glow">
                                                            <span className="placeholder col-12"></span>
                                                        </td>
                                                    </tr>)
                                                }
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Untuk text */}
                <div className="modal fade" id="modalKontenText" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Konten</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={this.state.Detail.Konten}
                                            onChange={(e) => this.setState({ Detail: { ...this.state.Detail, Konten: e } })}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['link', 'image'],
                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                                                    [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#cccccc', '#888888'] }],
                                                    ['clean'],
                                                ],
                                            }}
                                        />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name='Konten' value={this.state.Detail.Konten} />
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusText" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusText">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalText'>Batal</button>
                                    <button type="submit" className="btn btn-default"> <i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Icon */}
                <div className="modal fade" id="modalKontenBodyKenapa" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Konten</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={this.state.ObjKonten.keterangan}
                                            onChange={(e) => this.setState({ ObjKonten: { ...this.state.ObjKonten, keterangan: e } })}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['link', 'image'],
                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                                                    [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#cccccc', '#888888'] }],
                                                    ['clean'],
                                                ],
                                            }}
                                        />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.Konten)} />
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddArrayKonten({ icon: "", text: "" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Ikon</th>
                                                    <th>Text</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <input type="text" value={tr.icon} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.ObjKonten;
                                                                    let card = data.card;
                                                                    card[ii].icon = e.target.value;
                                                                    data.card = card;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.text} className='form-control form-control-sm' onChange={(e) => {
                                                                    let data = this.state.ObjKonten;
                                                                    let card = data.card;
                                                                    card[ii].text = e.target.value;
                                                                    data.card = card;
                                                                    this.setState({ Konten: data });
                                                                }} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalBodyKenapa'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className='fas fa-save'></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Team */}
                <div className="modal fade" id="modalKontenArrayTeam" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.KontenTeam)} />
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Email</th>
                                                    <th>Alamat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataTeam.map((tr, i) => {
                                                        let Status = false;
                                                        for (let dd of this.state.KontenTeam) if (dd == tr.ID) Status = true;
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" name='IDTeam[]' type="checkbox" role="switch" id={"IDTeam" + i} checked={Status} onChange={(e) => {
                                                                            let data = this.state.KontenTeam;
                                                                            if (e.target.checked == true) {
                                                                                data.push(tr.ID);
                                                                                this.setState({ KontenTeam: data });
                                                                            } else {
                                                                                let temp = [];
                                                                                for (let dd of data) {
                                                                                    if (dd != tr.ID) temp.push(dd);
                                                                                }
                                                                                this.setState({ KontenTeam: temp });
                                                                            }
                                                                        }} />
                                                                        <label className="form-check-label" htmlFor={"IDTeam" + i}>
                                                                            <img src={host.replace("cp", "") + tr.Foto} width="100px" height="100px" />
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td>{tr.Nama}</td>
                                                                <td>{tr.Telp}</td>
                                                                <td>{tr.Email}</td>
                                                                <td>{tr.Alamat}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayTeam'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Images */}
                <div className="modal fade" id="modalKontenArrayImages" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={this.state.Konten} />
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddArrayKonten({ "foto": "", "nama": "", "keterangan": "" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        let images = tr.foto == "" ? imgUpload : tr.foto;
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <label htmlFor={`fileInput${ii}`} className="custom-file-upload">
                                                                    <input type="file" id={`fileInput${ii}`} accept='image/*' style={{ display: "none" }} onChange={(e) => this.openFile(e, ii)} />
                                                                    <img src={images} width="100px" id={`img-${ii}`} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.nama} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'nama', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.bagian} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'bagian', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayImages'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Produk */}
                <div className="modal fade" id="modalKontenArrayProduk" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Konten" value={this.state.Konten} />
                                    <button type="button" className='btn btn-sm btn-default' onClick={() => this.handleAddArrayKonten({ "foto": "", "keterangan": "", "nama": "", "rating": 5, "faforit": true, "harga": 10000, "terjual": "100" })}>Tambah</button>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Rating</th>
                                                    <th>Harga</th>
                                                    <th>Faforit</th>
                                                    <th>Terjual</th>
                                                    <th>Keterangan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ArrKonten.map((tr, ii) => {
                                                        let images = tr.foto == "" ? imgUpload : tr.foto;
                                                        return (<tr key={ii}>
                                                            <td><i className='fas fa-trash-alt' onClick={() => this.handleDeleteArrayKonten(ii)}></i></td>
                                                            <td>
                                                                <label htmlFor={`fileInput${ii}`} className="custom-file-upload">
                                                                    <input type="file" id={`fileInput${ii}`} accept='image/*' style={{ display: "none" }} onChange={(e) => this.openFile(e, ii)} />
                                                                    <img src={images} width="100px" id={`img-${ii}`} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.nama} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'nama', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="number" value={tr.rating} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'rating', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.harga} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'harga', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id={"chkFavorit" + ii} onChange={(e) => this.handleChangeArrayKonten(ii, 'faforit', e.target.checked)} checked={tr.faforit} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.terjual} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'terjual', e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tr.keterangan} className='form-control form-control-sm' onChange={(e) => this.handleChangeArrayKonten(ii, 'keterangan', e.target.value)} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayProduk'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Banner Atas */}
                <div className="modal fade" id="modalKontenBanerAtas" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <input type='hidden' name="ChangeImg" value="tidak" />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Konten</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={this.state.ObjKonten.keterangan}
                                            onChange={(e) => this.setState({ ObjKonten: { ...this.state.ObjKonten, keterangan: e } })}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['link', 'image'],
                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                                                    [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#cccccc', '#888888'] }],
                                                    ['clean'],
                                                ],
                                            }}
                                        />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Background Images</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input name="Files" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e, true)} />
                                            <img src={this.state.ImagesSrc} htmlFor="BackgroundImage" style={{ width: "100%" }} />
                                        </label>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.ObjKonten)} />
                                    <input type="hidden" name="Foto" value={this.state.ImagesSrc} />
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayProduk'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Dashboard;
