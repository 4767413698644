import React, { Fragment } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Token, compressImageBase64, resizeImageBase64, host, api, pesan, isJson, submitForm, saiki } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';
import imgLoading from '../assets/img/loading-upload.gif';

class MasterProduk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            JmlData: 1,
            Order: "Nama",
            By: "ASC",
            Page: 1,
            q: "",
            lblSubmit: "fa fa-save",
            btnSubmit: false,
            thead: [],
            tbody: [],
            Detail: { Keterangan: "" }
        };
    }

    componentDidMount() {
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("/master", { act: "data konsultasi", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "200px" },
                { text: "Nama", field: "Nama", sort: "Nama" },
                { text: "Telp", field: "Telp", sort: "Telp" },
                { text: "Email", field: "Email", sort: "Email" },
                { text: "Keterangan", field: "Keterangan", sort: "Keterangan", type: "html" },
                { text: "Status", field: "SSTS", sort: "Status" }
            ];
            this.setState({
                tbody: sql.data,
                thead: thead,
                JmlData: sql.j,
                Detail: { Keterangan: "" },
                Status: true,
                lblSubmit: "fas fa-save",
                btnSubmit: false
            });
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    handleDelete(data) {
        this.setState({ ID: data.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDelete'), {});
        modal.show("#modalDelete");
    }

    async handleProsesDelete(e) {
        let sql = await api("master", { act: "hapus konsultasi", ID: this.state.ID });
        if (sql.status == "sukses") {
            this.handleMain();
            pesan("Sukses", sql.pesan, "primary");
            document.getElementById('btnTutupModalDelete').click();
        } else {
            this.setState({ lblSubmit: "fas fa-trash", btnSubmit: "false" });
            pesan("Gagal", sql.pesan, "danger");
        }
    }

    handleProses(data) {
        this.setState({ Detail: data });
        let modal = new window.bootstrap.Modal(document.getElementById('modalTestimoni'), {});
        modal.show("#modalTestimoni");
    }

    render() {
        return (
            <Fragment>
                <div className="main-header"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">{this.props.Title}</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-comment-medical", text: "Testimoni", opsi: (e) => this.handleProses(e) },
                                { icon: "fas fa-trash", text: "Hapus", opsi: (e) => this.handleDelete(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* modal Hapus */}
                <div className="modal fade" id="modalTestimoni" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'master', fn: (e) => this.handleMain() })} className='needs-validation' noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Proses Konsultasi</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "proses konsultasi" : "proses konsultasi"} />
                                    <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                    <div className='form-group'>
                                        <label>Nama</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Nama' name='Nama' value={this.state.Detail.Nama || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Nama = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Telp</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Telp' name='Telp' value={this.state.Detail.Telp || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Telp = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Email</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Email' name='Email' value={this.state.Detail.Email || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Email = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' placeholder='Silahkan isi Keterangan' name='Keterangan' value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select className='form-select' name='Status' value={this.state.Detail.Status || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Status = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required >
                                            <option value="0">Baru</option>
                                            <option value="1">Diproses</option>
                                            <option value="2">Selesai</option>
                                        </select>
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal" id='btnTutupModalTestimoni'>Batal</button>
                                    <button type="submit" className="btn btn-default" disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* modal Hapus */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Hapus Konsultasi</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h5>Apakah anda yakin akan menghapus data!</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal" id='btnTutupModalDelete'>Batal</button>
                                <button type="button" className="btn btn-danger" onClick={(e) => this.handleProsesDelete(e)} disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default MasterProduk;
