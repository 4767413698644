import React, { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { api, tanggalIndo, randomRgb } from '../Modul';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataPengunjung: {
                labels: [],
                datasets: [],
            },
            DataMenu: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'Dataset 1',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        borderColor: 'rgb(75, 192, 192)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        tension: 0.4,
                    },
                    {
                        label: 'Dataset 2',
                        data: [28, 48, 40, 19, 86, 27, 90],
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        tension: 0.4,
                    },
                ],
            },
            LabelMenu: []
        };
    }

    async componentDidMount() {
        let sql = await api("/konten", { act: "data dashboard" });
        if (sql.status == "sukses") {
            let LabelMenu = [], ArrMenu = [];
            for (let dd of sql.menu) {
                if (!LabelMenu.includes(dd.Menu)) {
                    LabelMenu.push(dd.Menu);
                    let ArrMenus = [];
                    for (let menu of sql.menu) {
                        if (menu.Menu == dd.Menu) ArrMenus.push(menu.CT);
                    }
                    let Color = randomRgb();
                    ArrMenu.push({
                        label: dd.Menu,
                        data: ArrMenus,
                        borderColor: Color,
                        backgroundColor: Color,
                        tension: 0.4,
                    });
                }
            }
            let DataMenu = {
                labels: LabelMenu,
                datasets: ArrMenu
            };

            let LabelPengunjung = [], arrPengunjung = [];
            for (let dd of sql.pengunjung) {
                if (!LabelPengunjung.includes(dd.Tanggal)) LabelPengunjung.push(tanggalIndo(dd.Tanggal));
                arrPengunjung.push(dd.CT);
            }
            let DataPengunjung = {
                labels: LabelPengunjung,
                datasets: [
                    {
                        label: 'Pengunjung',
                        data: arrPengunjung,
                        borderColor: 'rgb(75, 192, 192)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        tension: 0.4,
                    },
                ],
            };
            this.setState({ DataPengunjung: DataPengunjung, DataMenu: DataMenu });
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">{this.props.Title}</div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Line data={this.state.DataPengunjung} options={
                                    {
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Pengunjung Bulan ini',
                                            },
                                        },
                                    }
                                } />
                            </div>
                            <div className="col-6">
                                <Line data={this.state.DataMenu} options={
                                    {
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Menu Paling Sering',
                                            },
                                        },
                                    }
                                } />
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Dashboard;
