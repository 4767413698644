import React, { Fragment } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { compressImageBase64, resizeImageBase64, sizeBase64, extensionBase64, submitForm, api, host } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';
import imgLoading from '../assets/img/loading-upload.gif';

class KontenKontak extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [{ "type": "loading" }, { "type": "loading" }, { "type": "loading" }],
            Detail: {},
            JmlData: 0,
            Judul: "",
            Konten: "",
            Status: 1,
            NoUrut: "1",
            ObjKonten: {},
            ID: 0,
            Order: "NoUrut",
            By: "ASC",
            Page: 1,
            q: "",
            saveCls: "fa fa-save",
            thead: [
                { text: "", field: "", sort: "", type: "loading", width: "50px" },
                { text: "Judul", field: "Judul", sort: "Judul", type: "loading" },
                { text: "Posisi", field: "Posisi", sort: "Posisi", type: "loading" },
                { text: "No Urut", field: "NoUrut", sort: "NoUrut", type: "loading" },
                { text: "Status", field: "Status", sort: "Status", type: "loading" }
            ],
            tbody: [],
            ImageSrc: imgUpload,
            ChangeImg: "tidak",
            DataKlien: [],
            KontenBody: { team: [], keterangan: "" }
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("/konten", { act: "data konten", Menu: "Klien", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "50px" },
                { text: "Judul", field: "Judul", sort: "Judul" },
                { text: "Posisi", field: "Posisi", sort: "Posisi" },
                { text: "No Urut", field: "NoUrut", sort: "NoUrut" },
                { text: "Status", field: "Status", sort: "Status", type: "boolean" }
            ];
            this.setState({ tbody: sql.data, thead: thead, JmlData: sql.j, ImageSrc: imgUpload, ChangeImg: "tidak" });
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    async handleDetail(Detail) {
        let sql = await api("/konten", { act: "detail konten", ID: Detail.ID });
        if (sql.status == "sukses") {
            Detail.Konten = sql.data.Konten;
            if (Detail.KontenType == "ArrayObject") {
                let klien = await api("/master", { act: "data pelanggan", order: "Nama", by: "ASC", page: 1, q: "" }, true);
                let data = JSON.parse(Detail.Konten);
                if (Detail.Posisi == "Body") {
                    this.setState({
                        Judul: Detail.Judul,
                        KontenBody: data,
                        Status: Detail.Status,
                        NoUrut: Detail.NoUrut,
                        ID: Detail.ID,
                        DataKlien: klien.data
                    });
                    let modal = new window.bootstrap.Modal(document.getElementById('modalBody'), {});
                    modal.show("#modalBody");
                } else {
                    this.setState({
                        Judul: Detail.Judul,
                        ObjKonten: data,
                        Status: Detail.Status,
                        NoUrut: Detail.NoUrut,
                        ID: Detail.ID,
                        ImageSrc: host.replace("cp/", "") + data.background
                    });
                    let modal = new window.bootstrap.Modal(document.getElementById('modalKontenBanerAtas'), {});
                    modal.show("#modalKontenBanerAtas");
                }
            }
        }
    }

    handlePilihBG(e, compress = false) {
        let data = this.state.ObjKonten;
        this.setState({ ImageSrc: imgLoading });
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 && compress == true ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = fileSizeKB > 600 && compress == true ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
                        data.background = newRes;
                        this.setState({ Konten: data, ImageSrc: newRes, ChangeImg: "ya" });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">{this.props.Title}</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-edit", text: "Edit", opsi: (e) => this.handleDetail(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Untuk array Banner Atas */}
                <div className="modal fade" id="modalKontenBanerAtas" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <input type='hidden' name="ChangeImg" value="tidak" />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' value={this.state.ObjKonten.keterangan || ""} onChange={(e) => {
                                            let data = this.state.ObjKonten;
                                            let val = e.target.value;
                                            data.keterangan = val;
                                            this.setState({ ObjKonten: data });
                                        }}></textarea>
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Background Images</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input name='Files' type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                            <img src={this.state.ImageSrc} htmlFor="BackgroundImage" style={{ width: "100%" }} />
                                        </label>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.ObjKonten)} />
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayProduk'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk array Pengantar */}
                <div className="modal fade" id="modalBody" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <form onSubmit={(e) => submitForm(e, { crud: "konten", fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="edit konten" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Konnten</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Judul</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={this.state.Judul} onChange={(e) => this.setState({ Judul: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>No Urut</label>
                                        <input type="number" className='form-control' name='NoUrut' value={this.state.NoUrut} onChange={(e) => this.setState({ NoUrut: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' value={this.state.KontenBody.keterangan || ""} onChange={(e) => {
                                            let data = this.state.KontenBody;
                                            let val = e.target.value;
                                            data.keterangan = val;
                                            this.setState({ KontenBody: data });
                                        }}></textarea>
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th>Foto</th>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Email</th>
                                                    <th>Alamat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataKlien.map((tr, i) => {
                                                        let Status = false;
                                                        for (let dd of this.state.KontenBody.klien) if (dd == tr.ID) Status = true;
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" name='IDTeam[]' type="checkbox" role="switch" id={"IDTeam" + i} checked={Status} onChange={(e) => {
                                                                            let data = this.state.KontenBody;
                                                                            let team = data.klien;
                                                                            if (e.target.checked == true) {
                                                                                team.push(tr.ID);
                                                                                data.klien = team;
                                                                                this.setState({ KontenBody: data });
                                                                            } else {
                                                                                let temp = [];
                                                                                for (let dd of team) {
                                                                                    if (dd != tr.ID) temp.push(dd);
                                                                                }
                                                                                data.klien = temp;
                                                                                this.setState({ KontenBody: data });
                                                                            }
                                                                        }} />
                                                                        <label className="form-check-label" htmlFor={"IDTeam" + i}>
                                                                            <img src={host.replace("cp", "") + tr.Foto} width="100px" height="100px" />
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td>{tr.Nama}</td>
                                                                <td>{tr.Telp}</td>
                                                                <td>{tr.Email}</td>
                                                                <td>{tr.Alamat}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <input type="hidden" name="Konten" value={JSON.stringify(this.state.KontenBody)} />
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusArray" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusArray">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalArrayProduk'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.saveCls}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default KontenKontak;
