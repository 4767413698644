import React, { Fragment } from 'react';
import { Token, saiki, compressImageBase64, resizeImageBase64, submitForm, host, api, pesan } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';

class MasterMurid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            JmlData: 1,
            Order: "NamaDepan",
            By: "ASC",
            Page: 1,
            q: "",
            lblSubmit: "fa fa-save",
            btnSubmit: false,
            thead: [],
            tbody: [],
            ID: 0,
            NIS: "",
            NamaDepan: "",
            NamaBelakang: "",
            Telp: "",
            Email: "",
            TanggalLahir: saiki(),
            TempatLahir: "",
            JenisKelamin: "Laki-laki",
            Alamat: "",
            Foto: "",
            TanggalMasuk: saiki(),
            TanggalLulus: saiki(),
            KelasID: "",
            WaliID: "",
            Status: "Baru",
            dataKelas: [],
            dataWali: []
        };
    }

    componentDidMount() {
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("/master", { act: "data murid", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "120px" },
                { text: "NIS", field: "NIS", sort: "NIS" },
                { text: "Nama", field: "Nama", sort: "Nama" },
                { text: "Tanggal Lahir", field: "TanggalLahir", sort: "TanggalLahir", type: "date" },
                { text: "Jenis Kelamin", field: "JenisKelamin", sort: "JenisKelamin" },
                { text: "Status", field: "Status", sort: "Status" }
            ];

            this.setState({
                tbody: sql.data,
                thead: thead,
                JmlData: sql.j,
                NIS: "",
                NamaDepan: "",
                NamaBelakang: "",
                Telp: "",
                Email: "",
                TanggalLahir: saiki(),
                TempatLahir: "",
                JenisKelamin: "Laki-laki",
                Alamat: "",
                Foto: "",
                TanggalMasuk: saiki(),
                TanggalLulus: saiki(),
                KelasID: "",
                WaliID: "",
                Status: "Baru",
                lblSubmit: "fas fa-save",
                btnSubmit: false
            });
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        console.log(e.target.className);
        if (e.target.className == "page-link") {
            let Page = e.target.innerText == "" ? this.state.Page + 1 : e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    async handleDetail(data) {
        let sql = await api("master", { act: "detail murid", ID: data.ID }, true);
        if (sql.status == "sukses") {
            if (data != "baru") {
                this.setState({
                    ID: data.ID,
                    NIS: data.NIS,
                    NamaDepan: data.NamaDepan,
                    NamaBelakang: data.NamaBelakang,
                    Telp: data.Telp,
                    Email: data.Email,
                    TanggalLahir: data.TanggalLahir,
                    TempatLahir: data.TempatLahir,
                    JenisKelamin: data.JenisKelamin,
                    Alamat: data.Alamat,
                    Foto: data.Foto,
                    TanggalMasuk: data.TanggalMasuk,
                    TanggalLulus: data.TanggalLulus,
                    KelasID: data.KelasID,
                    WaliID: data.WaliID,
                    Status: data.Status,
                    dataWali: sql.wali,
                    dataKelas: sql.kelas
                });
            } else {
                this.setState({
                    ID: "",
                    NIS: "",
                    NamaDepan: "",
                    NamaBelakang: "",
                    Telp: "",
                    Email: "",
                    TanggalLahir: saiki(),
                    TempatLahir: "",
                    JenisKelamin: "Laki-laki",
                    Alamat: "",
                    Foto: "",
                    TanggalMasuk: saiki(),
                    TanggalLulus: saiki(),
                    KelasID: "",
                    WaliID: "",
                    Status: "Baru",
                    dataWali: sql.wali,
                    dataKelas: sql.kelas
                });
            }
            let modal = new window.bootstrap.Modal(document.getElementById('modalForm'), {});
            modal.show("#modalForm");
        }
    }

    handleDelete(data) {
        this.setState({ ID: data.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDelete'), {});
        modal.show("#modalDelete");
    }

    openFile(e) {
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            reader.onload = async () => {
                try {
                    let baru = await compressImageBase64(reader.result, 'jpeg', 0.8);
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = await resizeImageBase64(baru, height / 5, width / 5);
                        this.setState({ Foto: newRes });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header">
                    <button className='btn btn-default' onClick={() => this.handleDetail("baru")}>Tambah Murid</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">{this.props.Title}</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-edit", text: "Edit", opsi: (e) => this.handleDetail(e) },
                                { icon: "fas fa-trash", text: "Hapus", opsi: (e) => this.handleDelete(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Form Input edit */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { close: "btnTutupModalForm", crud: "master", fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            
                            <input type='hidden' name="act" value={this.state.ID == "" ? "tambah murid" : "edit murid"} />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah" : "Edit"} Murid</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>NIS</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi NIS' name='NIS' value={this.state.NIS} onChange={(e) => this.setState({ NIS: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Nama Depan</label>
                                                <input type="text" className='form-control' placeholder='Silahkan isi Nama Depan' name='NamaDepan' value={this.state.NamaDepan} onChange={(e) => this.setState({ NamaDepan: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Nama Belakang</label>
                                                <input type="text" className='form-control' placeholder='Silahkan isi Nama Belakang' name='NamaBelakang' value={this.state.NamaBelakang} onChange={(e) => this.setState({ NamaBelakang: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Tempat Lahir</label>
                                                <input type="text" className='form-control' placeholder='Silahkan isi Tempat Lahir' name='TempatLahir' value={this.state.TempatLahir} onChange={(e) => this.setState({ TempatLahir: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Tanggal Lahir</label>
                                                <input type="date" className='form-control' placeholder='Silahkan isi Tanggal Lahir' name='TanggalLahir' value={this.state.TanggalLahir} onChange={(e) => this.setState({ TanggalLahir: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Email</label>
                                                <input type="email" className='form-control' placeholder='Silahkan isi NIS' name='Email' value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Telp</label>
                                                <input type="number" maxLength={15} className='form-control' placeholder='Silahkan isi Telp' name='Telp' value={this.state.Telp} onChange={(e) => this.setState({ Telp: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Tanggal Masuk</label>
                                                <input type="date" className='form-control' placeholder='Silahkan isi Tanggal Masuk' name='TanggalMasuk' value={this.state.TanggalMasuk} onChange={(e) => this.setState({ TanggalMasuk: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Tanggal Lulus</label>
                                                <input type="date" className='form-control' placeholder='Silahkan isi Tanggal Lulus' name='TanggalLulus' value={this.state.TanggalLulus} onChange={(e) => this.setState({ TanggalLulus: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Wali Murid</label>
                                                <select className='form-select' name="WaliID" value={this.state.WaliID} onChange={(e) => this.setState({ WaliID: e.target.value })} required>
                                                    {
                                                        this.state.dataWali.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.NamaDepan} {opt.NamaBelakang}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih data</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <div className='form-group'>
                                                <label>Kelas</label>
                                                <select className='form-select' name="KelasID" value={this.state.KelasID} onChange={(e) => this.setState({ KelasID: e.target.value })} required>
                                                    {
                                                        this.state.dataKelas.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih data</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Alamat</label>
                                        <textarea className='form-control' placeholder='Silahkan isi Status Alamat' name='Alamat' value={this.state.Alamat} onChange={(e) => this.setState({ Alamat: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Jenis Kelamin</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="JenisKelamin" value="Laki-laki" id="radioLaki" onChange={(e) => this.setState({ JenisKelamin: e.target.checked == true ? "Laki-laki" : "Perempuan" })} checked={this.state.JenisKelamin == "Laki-laki" ? true : false} />
                                            <label className="form-check-label" htmlFor="radioLaki">
                                                Laki-laki
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="JenisKelamin" value="Perempuan" id="radioPerempuan" onChange={(e) => this.setState({ JenisKelamin: e.target.checked == true ? "Perempuan" : "Laki-laki" })} checked={this.state.JenisKelamin == "Perempuan" ? true : false} />
                                            <label className="form-check-label" htmlFor="radioPerempuan">
                                                Perempuan
                                            </label>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select className='form-select' name="Status" value={this.state.Status} onChange={(e) => this.setState({ Status: e.target.value })} required>
                                            <option value="Baru">Baru</option>
                                            <option value="Aktif">Aktif</option>
                                            <option value="Lulus">Lulus</option>
                                            <option value="Keluar">Keluar</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan pilih data</div>
                                    </div>
                                    <p></p>
                                    <input type='hidden' name="Foto" value={this.state.Foto} />
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <label>
                                            <input id="edtFoto" type='file' accept='image/*' className='d-none' onChange={(e) => this.openFile(e)} />
                                            <img src={this.state.Foto == "" ? imgUpload : this.state.Foto} htmlFor="edtFoto" style={{ width: "200px" }} />
                                        </label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalForm'>Batal</button>
                                    <button type="submit" className="btn btn-default" disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* modal Hapus */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form className='needs-validation' onSubmit={(e) => submitForm(e, { close: "btnTutupModalDelete", crud: "master", fn: (e) => this.handleMain(e) })} noValidate >
                            
                            <input type='hidden' name="act" value="hapus murid" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Murid</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghaus Murid!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal" id='btnTutupModalDelete'>Batal</button>
                                    <button type="submit" className="btn btn-danger" > <i className={this.state.lblSubmit}></i> Hapus</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default MasterMurid;
